import React from "react";
import Header from "./Header";
function Home() {
  return (
    <>
      <div className="container">
        <Header />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "70vw",
            margin: "auto",
            marginTop: 50,
          }}
        >
          <h2>Contact</h2>
          <p>
            Please click{" "}
            <a href="mailto:don@classydjservice.com">here to send an email!</a>
          </p>
        </div>
      </div>
    </>
  );
}

export default Home;
