import React from "react";
import Header from "./Header";

function Home() {
  return (
    <>
      <div className="container">
        <Header />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "70vw",
            margin: "auto",
            marginTop: 50,
          }}
        >
          <h2>Congratulations on your wedding!</h2>

          <p>
            All-inclusive packages – Our Wedding packages are all-inclusive
            meaning for one price you get it all. There are no charges for
            additional hours, extra DJ-related equipment, time, phone calls,
            meetings, ideas…anything!
          </p>

          <p>
            Personal planning – The person you talk to on the phone, the person
            you email, the person you meet with…is also the person at your
            Wedding. That is a comforting advantage for most brides and grooms
            and should bring peace of mind. We also help direct your special day
            from start to finish.
          </p>

          <p>
            Full service – Ceremony and reception at the same place? We can take
            care of both! You get all the music for your ceremony as well as
            high quality wireless microphones so your Wedding can be heard by
            all of your guests.
          </p>

          <p>
            Enormous music library – One of the biggest around. And we value the
            importance of bringing every song we own! Nothing is greater than
            one of your guests remembering their own special song. The feeling
            they get hearing it played at your Wedding will be remembered by
            them for a long time.
          </p>

          <p>
            Professional Sound Systems – Your Wedding deserves to not only sound
            spectacular but also look great. This is an area that is often
            overlooked in the planning process. Why settle for ordinary when you
            can have extraordinary?
          </p>

          <p>
            Insured – As an added value, our systems go out the door with full
            liability and property protection. Some venues require this. We feel
            it’s an important benefit to you.
          </p>

          <p>Just added…Our “Video Media Option”</p>
          <p>
            Touch of Class DJ Service has teamed up with Siro Bignotti of
            Southern Oregon Audio/Visual, one of the state’s top Sound and Video
            technicians to create our new “Video Media Option “. While your
            ceremony is underway, we can video the event and play it on large
            screens or on LCD flat panel monitors for all your guests to view.
            It’s unique, memorable, emotional, and one-of-a-kind. Your guests
            will never forget your wedding!
          </p>

          <p>
            You can find comfort in selecting Touch of Class D.J. Service to
            help you with the most important day of your life.
          </p>

          <p>Your special day is in good hands.</p>
        </div>
      </div>
    </>
  );
}

export default Home;
