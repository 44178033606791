import React from "react";

function Home() {
  return (
    <>
      <div className="container">
        <div
          style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
        >
          <img src={"/don.jpg"} alt="Don Monette - DJ" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "70vw",
            margin: "auto",
            marginTop: 50,
          }}
        >
          <h2>Don Monette, Owner & Master of Ceremonies Touch of Class</h2>
          <p>
            After many years of entertaining the Rogue Valley, Don has officially retired.
          </p>
        </div>
      </div>
    </>
  );
}

export default Home;
