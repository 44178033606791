import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Touch of Class DJ Service</h2>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 20 }}>
            <Link to={"/"}>Home</Link>
          </div>
          <div style={{ marginRight: 20 }}>
            <Link to={"/weddings"}>Weddings</Link>
          </div>
          <div style={{ marginRight: 20 }}>
            <Link to={"/contact"}>Contact</Link>
          </div>
        </div>
      </div>
      <div>
        <h3>Don Monette – Your master of Ceremonies!</h3>
      </div>
    </>
  );
}

export default Header;
